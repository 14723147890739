<template>
    <section class="oj-video-bg" role="presenation">
        <video autoplay class="oj-video-bg__video" muted playsinline preload="auto" ref="video">
            <slot></slot>
        </video>
    </section>
</template>

<style lang="scss">
@import '@/assets/styles/components/_video-bg.scss';
</style>