import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    component: HomeView,
    name: 'home',
    path: '/',
  },
  {
    component: () => import(/* webpackChunkName: "slide-view" */ '../views/SlideView.vue'),
    name: 'slide',
    path: '/slide/:id',
  },
  {
    component: () => import(/* webpackChunkName: "slide-view" */ '../views/OutroView.vue'),
    name: 'outro',
    path: '/outro',
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
