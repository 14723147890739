import { reactive } from 'vue';

export const store = reactive({
    // strings
    loading: true,
    selectedDonation: '',
    triggered: false,

    // objects
    advance: {},
    music: {},
    slides: {
        'elk-bw': {
            desc: 'Last Dollar Road near Ridgway',
            start: 0,
            stop: 290,
        },
        'elk-ill': {
            start: 300,
            stop: 590,
        },
        'lake-bw': {
            desc: 'View west from the Denver Museum of Nature & Science',
            start: 600,
            stop: 890,
        },
        'lake-ill': {
            start: 900,
            stop: 1190,
        },
        'ducks-bw': {
            desc: 'View northwest from Larimer St. between 26th St. in the RINO district',
            start: 1200,
            stop: 1490
        },
        'ducks-ill': {
            start: 1500,
            stop: 1790
        },
        'sculpture-bw': {
            desc: 'View north from Champa St. and Speer Blvd. looking at The Dancers and the DCPA',
            start: 1800,
            stop: 2090
        },
        'sculpture-ill': {
            start: 2100,
            stop: 2390
        },
        'clocktower-bw': {
            desc: 'View southeast looking down the 16th St. Mall from the top of the Millennium Bridge',
            start: 2400,
            stop: 2690
        },
        'clocktower-ill': {
            start: 2700,
            stop: 2990
        },
        'wings-bw': {
            desc: 'View southeast from the Little Raven St. looking towards Millenium Bridge',
            start: 3000,
            stop: 3290
        },
        'wings-ill': {
            start: 3300,
            stop: 3590
        }
    },
    video: {
        el: '',
        frame: 0,
        maxFrame: 0
    },

    playSound() {
        this.triggered = true;

        setTimeout(() => {
            this.triggered = false;
        }, 500);

        this.music.play();

        this.advance.play();
    }
});