<template>
  <figure class="oj-bg-img" role="presentation">
    <img alt="Stars in the night sky" src="@/assets/img/oj-2022-bg.jpg" />
  </figure>

  <div class="oj-container">
    <HeaderNavigation />

    <div class="oj-page-wrapper">
      <Transition>
        <TransparentBackgroundVideo class="oj-slideshow" ref="slideshow" v-show="$route.path.includes('slide')">
          <source src="@/assets/video/oj-slideshow.mov" type='video/mp4; codecs="hvc1"' />

          <source src="@/assets/video/oj-slideshow.webm" type="video/webm" />
        </TransparentBackgroundVideo>
      </Transition>

      <audio class="oj-music" controls='false' loop ref="music">
        <source src="@/assets/audio/music.mp3" type="audio/mpeg">
      </audio>


      <audio class="oj-music" controls='false' ref="advance">
        <source src="@/assets/audio/advance.mp3" type="audio/mpeg">
      </audio>

      <RouterView :class="[store.triggered ? 'active' : '']" v-slot="{ Component }">
        <Transition>
          <component :is="Component"></component>
        </Transition>
      </RouterView>
    </div>

    <div class="mobile-donations">
      <OutroDonations />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/main';
</style>

<script>
import { store } from '@/_store';
import HeaderNavigation from './components/HeaderNavigation.vue';
import OutroDonations from './components/OutroDonations.vue';
import TransparentBackgroundVideo from './components/TransparentBackgroundVideo.vue';

export default {
  components: {
    TransparentBackgroundVideo,
    HeaderNavigation,
    OutroDonations
  },
  data() {
    return {
      store
    }
  },
  async mounted() {
    this.store.advance = this.$refs.advance;

    this.store.advance.volume = 0.2;

    this.store.music = this.$refs.music;

    this.store.music.volume = 0.2;

    this.store.video.el = this.$refs.slideshow.$refs.video;
  }
}
</script>