<template>
  <div class="oj-home oj-page">
    <BackgroundVideo :video-src="bgVideoSrc" />

    <TransparentBackgroundVideo class="oj-home-animation">
      <source src="@/assets/video/oj-intro-animation.mov" type='video/mp4; codecs="hvc1"' />

      <source src="@/assets/video/oj-intro-animation.webm" type="video/webm" />
    </TransparentBackgroundVideo>

    <RouterLink aria-label="Watch the slideshow" class="oj-home__btn" to="/slide/elk-bw" @click="store.playSound()" />
  </div>
</template>

<style lang="scss">
@import '@/assets/styles/pages/home';
</style>

<script>
import { store } from '@/_store';
import BackgroundVideo from '../components/BackgroundVideo.vue';
import TransparentBackgroundVideo from '../components/TransparentBackgroundVideo.vue';

const
  getBgVideoSrc = () => {
    return import('@/assets/video/oj-intro-bg.mp4').then(response => response.default);
  }

export default {
  components: {
    BackgroundVideo,
    TransparentBackgroundVideo
  },
  name: 'HomeView',
  data() {
    return {
      store,
      animationVideoMovSrc: '',
      animationVideoWebmSrc: '',
      bgVideoSrc: ''
    }
  },
  methods: {
    startMusic() {
      this.store.music.play();
    }
  },
  async mounted() {
    this.bgVideoSrc = await getBgVideoSrc();
  }
}
</script>
