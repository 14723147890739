<template>
    <section class="oj-video-bg" role="presenation">
        <video autoplay class="oj-video-bg__video" :loop="videoLoop" :muted="videoMute" playsinline preload="auto" :src="videoSrc" ref="video" :type="videoType" />
    </section>
</template>

<style lang="scss">
@import '@/assets/styles/components/_video-bg.scss';
</style>

<script>
import { store } from '@/_store.js';

export default {
    data() {
        return {
            store
        }
    },
    props: {
        videoLoop: {
            default: true,
            type: Boolean
        },
        videoMute: {
            default: true,
            type: Boolean
        },
        videoSrc: String,
        videoType: {
            default: 'video/mp4',
            type: String
        }
    }
}
</script>