<template>
    <section class="oj-donations">
        <header class="oj-donations__txt">
            <h2>Our partner organizations are picturing a better future for our community.</h2>

            <p>Help us choose one to give a little extra to this season. Votes will be tallied 12/31.</p>
        </header>

        <div class="oj-donations__cards">
            <article class="oj-donations__card" :key="recipient" v-for="recipient in donationRecipients">
                <a :href="recipient.link">
                    <figure class="oj-donations__card-img">
                        <img :alt="`${recipient.name} logo`" :class="[recipient.id]" :src="recipient.imgUrl" />
                    </figure>

                    <h3>{{ recipient.name }}</h3>
                </a>

                <button :class="['oj-donations__card-btn', { 'selected': store.selectedDonation == recipient.name }]" @click="(store.selectedDonation = recipient.name)" v-html="[store.selectedDonation == recipient.name ? 'Great choice!' : 'Vote']"></button>
            </article>
        </div>
    </section>

    <footer class="oj-footer">
        <a class="oj-footer__link" href="http://2021.ottenjohnsonholiday.com/">Visit 2021's Page</a>

        <a class="oj-footer__link" href="http://2020.ottenjohnsonholiday.com/">Visit 2020's Page</a>

        <a class="oj-footer__link" href="http://2019.ottenjohnsonholiday.com/">Visit 2019's Page</a>
    </footer>
</template>

<style lang="scss">
@import '@/assets/styles/components/donations';
</style>

<script>
import { store } from '@/_store.js';

const
    getTgpImgSrc = () => {
        return import('@/assets/img/tgp-logo.jpg').then(response => response.default);
    },
    getHfhImgSrc = () => {
        return import('@/assets/img/hfh-logo.jpg').then(response => response.default);
    },
    getHsImgSrc = () => {
        return import('@/assets/img/hs-logo.jpg').then(response => response.default);
    },
    getUpImgSrc = () => {
        return import('@/assets/img/up-logo.jpg').then(response => response.default);
    },
    getVocImgSrc = () => {
        return import('@/assets/img/voc-logo.jpg').then(response => response.default);
    },
    getCookie = (cname) => {
        let
            name = cname + '=',
            ca = document.cookie.split(';');

        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];

            while (c.charAt(0) == ' ')
                c = c.substring(1);

            if (c.indexOf(name) == 0)
                return c.substring(name.length, c.length);
        }

        return '';
    };

export default {
    data() {
        return {
            donationRecipients: {
                tgp: {
                    id: 'theGatheringPlace',
                    link: 'https://tgpdenver.org/',
                    name: 'The Gathering Place',
                    imgUrl: ''
                },
                hfh: {
                    id: 'habitatForHumanity',
                    link: 'https://habitatcolorado.org/',
                    name: 'Habitat for Humanity of Colorado',
                    imgUrl: ''
                },
                hs: {
                    id: 'humaneSociety',
                    link: 'https://www.boulderhumane.org/',
                    name: 'Humane Society of Boulder Valley',
                    imgUrl: ''
                },
                up: {
                    id: 'urbanPeak',
                    link: 'https://www.urbanpeak.org/',
                    name: 'Urban Peak',
                    imgUrl: ''
                },
                voc: {
                    id: 'volunteersOutdoorColorado',
                    link: 'https://www.voc.org/',
                    name: 'Volunteers for Outdoor Colorado',
                    imgUrl: ''
                }
            },
            store
        }
    },
    async mounted() {
        this.donationRecipients.tgp.imgUrl = await getTgpImgSrc();

        this.donationRecipients.hfh.imgUrl = await getHfhImgSrc();

        this.donationRecipients.hs.imgUrl = await getHsImgSrc();

        this.donationRecipients.up.imgUrl = await getUpImgSrc();

        this.donationRecipients.voc.imgUrl = await getVocImgSrc();
    },
    watch: {
        'store.selectedDonation'() {
            let val = `${this.store.selectedDonation}`.toLowerCase().replace(/\s+/g, "_");

            if (getCookie('voted') != 'true') {
                this.$gtag.event(val, {
                    event_category: '2022',
                    event_label: this.store.selectedDonation,
                    value: 1
                });
            }

            document.cookie = 'voted=true';
        }
    }
}
</script>